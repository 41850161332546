@tailwind base;
@tailwind components;
@tailwind utilities;
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');*/

html,
#root,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden !important;
  color: #374151 !important;
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
  /*font-family: 'DejaVu Sans', 'Arial', sans-serif;*/
  /*font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;*/
}
.react-pdf__Page__textContent {
  display: none !important;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  src: url('../public/fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  src: url('../public/fonts/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  src: url('../public/fonts/DejaVuSans.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url('../public/fonts/DejaVuSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-style: italic;
  src: url('../public/fonts/DejaVuSans-Oblique.ttf') format('truetype');
}
@font-face {
  font-family: 'Noto Color Emoji';
  src: url('../public/fonts/NotoColorEmoji-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Verdana';
  src: url('https://fonts.cdnfonts.com/css/verdana');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../public/fonts/Montserrat-Regular.woff') format('woff');
  /* Include additional font formats if needed */
}
/*
@font-face {
  font-family: 'Segoe UI Emoji';
  src: url('path/to/segoeuiemoji.woff2') format('woff2'),
       url('path/to/segoeuiemoji.woff') format('woff');
}
*/
.joypixels {
  display: inline;
  vertical-align: middle;
  height: 1em;
  width: 1em;
}
.pdfFont {
  font-family: 'Montserrat', sans-serif !important;
}
.testhejhej {
  font-size: 10px !important;
  font-weight: normal;
  /*font-family: 'Noto Color Emoji', 'Poppins', sans-serif !important;*/
  /*font-family: 'DejaVu Sans', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
.testhejhej p {
  font-size: 10px !important;
  line-height: normal !important;
  /*font-family: 'Noto Color Emoji', 'Poppins', sans-serif !important;*/
  /*font-family: 'DejaVu Sans', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
.testhejhej span {
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
.testhejhej ul {
  font-size: 10px !important;
  line-height: normal !important;
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
.testhejhej h1 {
  font-size: 18px !important;
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
.testhejhej h2 {
  font-size: 16px !important;
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
.testhejhej h3 {
  font-size: 14px !important;
  /*font-family: 'Montserrat', 'Arial', sans-serif !important;*/
  /*font-family: 'Montserrat', 'Noto Color Emoji', sans-serif;*/
  /*font-family: 'Montserrat', 'DejaVu Sans', sans-serif !important;*/
  font-family: 'Montserrat', 'Arial', sans-serif !important;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.swal2-container {
  z-index: 12000 !important;
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-tel-input {
  margin-top: 5px !important;
  font-weight: bold !important;
  color: #424242 !important;
}
.react-tel-input input {
  width: 100% !important;
  /*shadow*/
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  /*padding*/
  padding-left: 3rem !important;
  padding-right: 1rem !important;

  padding-top: 0.61rem !important;
  padding-bottom: 0.61rem !important;

  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;

  border-width: 1px !important;

  border-radius: 0.75rem !important;

  height: auto !important;
  margin-top: 50px !important;
}

.flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white !important;
  border: 1px solid white !important;
  border-radius: 0.75rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.scrollbar-thin {
  /* Foreground, Background */
  scrollbar-color: #1e293b #0f172a;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 3px; /* Mostly for vertical scrollbars */
  height: 3px; /* Mostly for horizontal scrollbars */
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #ddd; /*rgb(154, 148, 227);*/
  border-radius: 10px;
}
.scrollbar-thin::-webkit-scrollbar-track {
  /* Background */
  background: transparent;
}
.checkInOutBlock {
  position: absolute;
  top: 10px;
  left: 29.8%;
  width: 29.8%;
  height: 30px;
  background-color: rgba(0, 128, 0, 0.6);
  color: white;
  line-height: 15px;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
}
.scheduleBlock {
  position: absolute;
  top: 5px;
  left: 29.8%;
  width: 29.8%;
  height: 40px;
  background-color: rgba(255, 255, 0, 0.6);
  color: white;
  line-height: 20px;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 9;
}
.calendar {
  margin: auto;
}

.calendar .wrap {
  padding: 0 55px;
}

.currentYear {
  font-size: 42px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 450;
  line-height: 0;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #000;
  padding: 4px;
  margin: 50px auto 50px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: auto 0 50px;
}

.controls .btn {
  font-size: 24px;
  line-height: 1;
  font-weight: 450;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #fff;
  border: none;
  background-color: rgb(3, 157, 204);
  padding: 13px 20px;
  margin: 0;
  cursor: pointer;
  border-radius: 8%;
}

.year {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 -10px;
}

.month {
  width: 100%;
  padding: 0 10px;
  margin: 40px;
  box-sizing: border-box;
}

.singleCol .month {
  width: 100%;
}

.twoCol .month {
  width: 50%;
}

.monthName {
  font-size: 24px;
  line-height: 1;
  font-weight: 450;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: rgb(0, 0, 0);
  padding: 0;
  margin: 0 auto 20px;
}

.month .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
}

.day {
  width: 14.166666666667%;
  text-align: center;
  padding: 12px;
  box-sizing: border-box;
}

.dayOfTheWeek .day {
  font-weight: 800;
}

.booked {
  background-color: rgb(216, 59, 59);
  color: #fff;
  border-radius: 55%;
  font-weight: 450;
}
.isLateCheckout {
  position: relative;
  background-color: transparent;
  overflow: hidden;
  color: #fff;
}
.isLateCheckout > span {
  background: #fff;
  color: #000;
  padding: 0 3px;
}
.isLateCheckout > span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(248, 80, 50, 1);
  background: linear-gradient(to bottom right, #000 50%, transparent 50%);
  z-index: -1;
}
.key {
  display: flex;
  flex-direction: row;
  margin: 0 absolute;
}
.key .type {
  margin: 0 10px 0 0;
}

.key .type span {
  position: relative;
  display: flex;
  line-height: 30px;
}

.key .type span::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 20px 0 0;
}

.bookedType span::before {
  background: #000;
}

.lateCheckoutType span::before {
  background: rgba(248, 80, 50, 1);
  background: linear-gradient(to bottom right, #000 50%, transparent 50%);
}

@media (max-width: 980px) {
  .month {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .month,
  .twoCol .month {
    width: 100%;
  }
}
.react-datepicker__day--selected {
  background-color: #7eb5f4 !important;
  border-radius: 20% !important;
  padding-right: 1px !important;
}
.react-datepicker__day {
  border-radius: 80% !important;
  padding-right: 1px !important;
}

.react-datepicker__navigation {
  padding-top: 20px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #717171 !important;
}

.react-datepicker__day:hover {
  background-color: #a2c7f0 !important;
  color: #fffefe !important;
  border-radius: 80% !important;
}
.react-datepicker__header {
  background-color: #ffffff !important;
}

.react-datepicker__navigation--next {
  background-color: #ffffff !important;
  border-radius: 100% !important;
  padding-top: 10px !important;
  padding-left: 7px !important;
  margin-top: 12px !important;
  margin-right: 15px !important;
}

.react-datepicker {
  text-transform: capitalize !important;
 /*  border: 0.5px solid !important;
  border-color: #7eb5f4 !important;
  border-bottom-left-radius: 5% !important;
  border-bottom-right-radius: 5% !important;
  border-top-left-radius: 5% !important;
  border-top-right-radius: 5% !important; */
  border: none !important;
  box-shadow: none !important;
  background-color: #f6f6f6 !important;
  overflow: hidden !important;
}
.react-datepicker__navigation--previous {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 100% !important;
  padding-top: 10px !important;
  padding-right: 7px !important;
  margin-top: 12px !important;
  margin-left: 15px !important;
}

.react-datepicker__navigation--previous svg {
  color: #000 !important;
}
.react-datepicker__navigation-icon {
  color: #22b824 !important;
}
.react-datepicker__day--disabled:hover {
  cursor: not-allowed;
}
.react-datepicker__day-names {
  background-color: #7eb5f4 !important;
  border-bottom-color: #fff !important;
}
.react-datepicker__day-name {
  color: #fff !important;
}
.react-datepicker__current-month {
  margin-bottom: 20px !important;
  margin-top: 10px !important;
  color: #7eb5f4 !important;
}
.react-datepicker__navigation {
  color: #000 !important;
  
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: black !important;
  
}

.react-datepicker-popper {
  width: 190px !important;
  
}

/* Start of Time Picker */

.react-datepicker__header--time--only {
  border-top-right-radius: 20% !important;
  border-top-left-radius: 20% !important;
  padding-top: 10px !important;
  background-color: #f6f6f6 !important;
}

.react-datepicker--time-only {
  width: 120px !important;
  flex: auto !important;
}
.react-datepicker__time-container {
  width: 120px !important;
}
.react-datepicker__time-list {
  margin-right: 0px !important;
}
.react-datepicker__time-list-item {
  width: auto;
  border-radius: 20px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  color: black;
}
.react-datepicker__time-list-item:hover {
  color: black;
  border-radius: 20px !important;
  font-weight: bold;
}
.react-datepicker__time-list-item--disabled:hover {
  color: #ff0000 !important;
  cursor: not-allowed !important;
}
.react-datepicker-time__header {
  color: black !important;
  font-size: large !important;
  font-weight: 900 !important;
}
.react-datepicker--time-only {
  border-radius: 25px !important;
}
.react-datepicker__time-list-item--selected {
  background-color: #fff !important;
  color:#000 !important;
}

.react-datepicker__time{
  background-color: #f6f6f6 !important;
}
/* End of Time Picker */
.react-tel-input input {
  border-radius: 25px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.selected-flag:hover {
  border-radius: 20px !important;
}
.selected-flag {
  border-radius: 80% !important;
  margin-left: 5px !important;
  padding: 10px !important;
}
.flag-dropdown {
  border-radius: 25px !important;
}
.country-list {
  border-radius: 15px !important;
}
.focusRing1 {
  outline: none !important;
  border-top: 1px solid #7eb5f4;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.focusRing2 {
  outline: none !important;
  border-top: 1px solid #7eb5f4;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.swal2-html-container {
  font-weight: bold !important;
}
.truncate {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-more {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: blue;
  background-color: white;
}
.expanded .show-more {
  display: none;
}
.word-wrap {
  overflow-wrap: break-word;
}
.swal2-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important;
  width: 70% !important;
  padding: 20px !important;
}
.swal2-popup {
  border-radius: 40px !important;
}
.swal2-actions .swal2-confirm,
.swal2-actions .swal2-cancel {
  flex: 1 !important;
  margin-left: 25px !important;
  margin-right: 25px !important;
  border-radius: 200px !important;
  border: none !important;
  font-weight: bold;
}
.swal2-actions .swal2-cancel {
  background-color: rgb(252, 158, 158) !important;
  color: white !important;
}

.swal2-actions .swal2-cancel:hover {
  background-color: rgb(252, 158, 158) !important;
  color: white !important;
}
.swal2-actions .swal2-confirm:focus {
  outline: none !important;
  box-shadow: none !important;
}
.swal2-actions .swal2-cancel:focus {
  outline: none !important;
  box-shadow: none !important;
}
.slider-container {
  display: flex;
  align-items: center;
}
.slider {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;
}

.slider::before {
  position: absolute;
  content: '';
  width: 38px;
  height: 38px;
  background-color: white;
  border-radius: 19px;
  left: 1px;
  top: 1px;
  transition: all 0.3s;
}

:root {
  --button-bg-color: #6bbd73;
}

.slider[data-value='1']::before {
  left: calc(50% - 19px);
}

.slider[data-value='2']::before {
  left: calc(100% - 39px);
}

.slider-text {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.slider-text span {
  display: inline-block;
  width: 33.33%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
}
.slider-wrapper {
  position: relative;
  display: inline-block;
  width: 84px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #000;
  overflow: hidden;
}

.slider-two {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 28px;
  background-color: var(--button-bg-color);
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  /* border: 1px solid #6BBD73; */
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);*/
}
.slider-two-meeting {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 28px;
  background-color: #ffc27e;
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  /* border: 1px solid #ffc27e; */
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);*/
}

.slider-two[data-value='1'] {
  background-color: #cfcfcf;
  /* border: 1px solid #8f8f8f; */
}
.slider-two-meeting[data-value='1'] {
  background-color: #cfcfcf;
  /* border: 1px solid #8f8f8f; */
}

.slider-two::before {
  position: absolute;
  content: '';
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  left: 10px;
  top: 49%;
  transform: translateY(-49%);
  transition: all 0.2s;
  /* border: 1px solid #ffffff; */
}
.slider-two-meeting::before {
  position: absolute;
  content: '';
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  left: 10px;
  top: 49%;
  transform: translateY(-49%);
  transition: all 0.2s;
  /* border: 1px solid #ffffff; */
}

.slider-two[data-value='1']::before {
  left: 1px;
  background-color: #ffffff;
}
.slider-two-meeting[data-value='1']::before {
  left: 1px;
  background-color: #ffffff;
}

.slider-two[data-value='2']::before {
  left: calc(100% - 26px);
  background-color: #ffffff;
}
.slider-two-meeting[data-value='2']::before {
  left: calc(100% - 26px);
  background-color: #ffffff;
}

.slider-two .slider-text {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
}
.slider-two .slider-text span {
  display: inline-block;
  width: 43%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
}
.custom-form-control:focus {
  box-shadow: 0 4px 10px 2px rgba(244, 126, 126, 0.396) !important;
}
.custom-form-control {
  --tw-shadow: 0 5px 5px 5px rgb(0 0 0 / 10),
    0 8px 10px -6px rgba(44, 207, 44, 0.1) !important;
}
.custom-form-control-phone {
  --tw-shadow: 0 5px 5px 5px rgb(0 0 0 / 10),
    0 8px 10px -6px rgba(44, 207, 44, 0.1) !important;
  border: #000 solid 2px !important;
}

.react-tel-input input:focus {
  box-shadow: 0px 1px -2px 2px rgba(23, 23, 23, 0.1) !important;
}

.react-tel-input .right-default {
  right: 10px;
}
.right-minutes-or-hours {
  right: 20px;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.custom-textarea {
  width: 500px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 10px;
}

.header input {
  width: 100%;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 18px;
}

.gray-line {
  height: 1px;
  background-color: gray;
  margin-top: 5px;
}

textarea.p-2 {
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 10px;
}
.slide.hidden {
  display: none;
}

.slide.active {
  display: block;
}
.active {
  display: block;
}

.hidden {
  display: none;
}
.icon-container:hover .absolute {
  color: var(--events-main-color, #76ff03); /* lägg in färg */
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-0 {
  transform: rotate(0deg);
}
.small-heart {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 10px; /* or any size */
  height: 10px; /* or any size */
  background-color: red; /* your heart color */
  /* Use your method to make it a heart shape, e.g., FontAwesomeIcon or CSS */
  opacity: 0;
  transform: scale(0.5);
}

/* This class is added to start the animation */
.shakeHeartWithFireworks {
  animation: shakeHeartWithFireworks 1s ease-in-out;
}

.popHeart {
  animation: popHeart 1s ease-in-out infinite;
}
.phone-input:empty:after {
  content: '•'; /* Unicode character for a dot */
  color: red; /* Color of the dot */
}
.react-datepicker__month-container {
  flex: auto;
}
.react-datepicker__month {
  flex: auto !important;
  height: auto !important;
  overflow-y: auto !important;
}
.notice-item {
  position: relative;
  /* other styles for your notice item */
}

.hover-button {
  display: none;
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  /* Additional button styling */
}

.notice-item:hover .hover-button {
  display: block;
}
.close-button-hover:hover {
  color: #cccc !important;
}
.react-tel-input {
  cursor: pointer !important;
  outline: none;
  border: none !important;
  box-shadow: none !important;
  font-weight: 500 !important;
}
.react-tel-input input {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
}
.react-tel-input input {
  cursor: pointer !important;
  border: none !important;
  border-radius: 25px !important;
  padding-top: 11px !important;
  padding-bottom: 10px !important;
}
/* Hide the default checkbox */
.hidden-checkbox {
  display: none;
}

/* Style for the custom checkbox */
.checkbox-style {
  display: inline-block;
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  border-radius: 50%; /* Makes it round */
  border: 2px solid #ccc; /* Border color, adjust as needed */
  cursor: pointer;
  position: relative;
  background-color: white; /* Background color */
}

/* Style when the checkbox is checked */
.hidden-checkbox:checked + .checkbox-style {
  background-color: lightblue; /* Background color when checked */
  border-color: lightblue; /* Border color when checked */
}

/* Optional: Style for the checkmark to appear when checked */
.hidden-checkbox:checked + .checkbox-style::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
}
/*.react-datepicker-popper {
  position: absolute;
  inset: 0px 52px auto auto !important;
  transform: translate3d(0px, 38px, 0px) !important;
}*/

.number-input {
  border: 1px solid #ccc; /* Stylish border */
  border-radius: 4px; /* Rounded corners */
  padding: 5px 10px;
  width: 150px; /* Adjust as needed */
}

/* Example styling for buttons, customize as needed */
.controls button {
  color: #333;
  font-size: 16px;
}
/* Styles to remove spinner from input */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input[type='number'] {
  -moz-appearance: textfield;
}
